<template>
  <div class="Home">
    <!-- 头部 -->
    <div class="head">

      <span v-if="projectTeamRole == '领队'" class="btn" @click="mentorCritique()">Mentor Critique</span>

      <span class="btn" @click="getPdf()">Aggregate</span>
      <el-dropdown>
        <i class="el-icon-setting" style="margin-right: 40px"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item ><div @click="logout()">Log Out</div></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <!-- <div class="templateHead">
      <h3>{{template_name}}
          <el-button v-if="project_id==='6087cb92a4640b8b5a8b4588' ||  project_id==='6279fa81a4640b5b678b4597'" type="primary" @click="sendEmail" style="margin-left:30px">我已完成简历填写，确认提交</el-button>
      </h3>
      <p>{{template_description}}</p>
    </div> -->
    <el-container class="center">
      <el-aside class="aside" width="20%">
        <!-- <el-menu :default-openeds="['1', '3']" @select="onselect" @open="onopen" @close="onclose">
          <el-submenu index="1" title="试试看" >
              <template slot="title"><i class="el-icon-message"></i>导航一</template>
              <el-menu-item index="1-1">选项1</el-menu-item>
              <el-menu-item index="1-2">选项2</el-menu-item>

          </el-submenu>
          <el-submenu index="2">
            <template slot="title"><i class="el-icon-menu"></i>导航二</template>
            <el-menu-item-group>
              <template slot="title">分组一</template>
              <el-menu-item index="2-1">选项1</el-menu-item>
              <el-menu-item index="2-2">选项2</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="分组2">
              <el-menu-item index="2-3">选项3</el-menu-item>
            </el-menu-item-group>
            <el-submenu index="2-4">
              <template slot="title">选项4</template>
              <el-menu-item index="2-4-1">选项4-1</el-menu-item>
            </el-submenu>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title"><i class="el-icon-setting"></i>导航三</template>
            <el-menu-item-group>
              <template slot="title">分组一</template>
              <el-menu-item index="3-1">选项1</el-menu-item>
              <el-menu-item index="3-2">选项2</el-menu-item>
            </el-menu-item-group>
            <el-menu-item-group title="分组2">
              <el-menu-item index="3-3">选项3</el-menu-item>
            </el-menu-item-group>
            <el-submenu index="3-4">
              <template slot="title">选项4</template>
              <el-menu-item index="3-4-1">选项4-1</el-menu-item>
            </el-submenu>
          </el-submenu>
        </el-menu> -->
        <el-collapse v-model="activeC">
          <el-collapse-item :title="template_name" name="default">
            <el-tree empty-text="No Data" :data="menuItems" :props="defaultProps" @node-click="handleNodeClick" :render-content="renderContent"></el-tree>
          </el-collapse-item>
        </el-collapse>
      </el-aside>

      <el-container style="margin-left:50px;background: #fff;">

        <el-main class="centerMain">
          <!-- <el-table :data="tableData">
            <el-table-column prop="date" label="日期" width="140">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="120">
            </el-table-column>
            <el-table-column prop="address" label="地址">
            </el-table-column>
          </el-table> -->
          <div class="toptitle">
            {{content.block_name || 'Please select a section on the left.'}}
            <span class="title-description" v-if="content.content_type === 'VIDEO'"> (This section has {{content_quiz.length}} quiz questions)</span>
            <span class="tip" v-if="content.block_require=='no'">Unnecessary</span>
            <span class="tip" v-if="content.block_require=='yes'">Required</span>
          </div>

          <div v-if="content.block_name" class="content_instruction">{{content.block_description || ''}}</div>
          <div v-if="content.block_name && checklist.length" class="check-box">
            <h3 v-if="checklist.length" style="margin-bottom:20px">Self-check list for this part:</h3>
            <div v-for="(item, index) in checklist" :key="item._id.$id" style="margin-top:10px">
              <el-checkbox  :label="index" :key="item.check_content" v-model="item.check_status" @change="handleCheckedCitiesChange(item)"><div class="check-box-item"><span style="background:rgba(0,114,255,0.1600);padding:2px 10px;margin-right:10px;border-radius:10px;color:#0E4890">{{item.check_type}}</span>{{item.check_content}}</div></el-checkbox>
            </div>
          </div>

          <div v-if="content.block_type === 'HTML'" style="height:calc(100% - 60px)">
            <div v-if="content.html_text" v-html="content.html_text"></div>
            <iframe v-if="!content.html_text && content.pdf_url" :src="content.pdf_url" width="100%" height="100%">

            This browser does not support PDFs. Please download the PDF to view it: <a :href="content.pdf_url">Download PDF</a>

            </iframe>

            <div v-if="!content.html_text && !content.pdf_url" style="margin-top:20px">Coming soon.</div>
          </div>
          <div v-if="content.block_type === 'VIDEO'">
            <div>
              <video :src="content.video_url" id="videoPlay" controls="controls" class="video" oncontextmenu="return false;" v-show="!isBeginQuiz" @timeupdate="timeupdate" @ended="videoEnd">Your browser does not support video play.</video>
              <Quiz :studentId="studentId" :quizs="quizs" :onDone="onQuizDone" />
            </div>
          </div>
          <el-dialog
            title="Video Play"
            custom-class="no-header-dialog"
            :visible.sync="dialogVisible"
            width="1165px"
            :height="300"
            center
            :show-close="false"
            destroy-on-close>
            <div class="video_container">
              <video class="video" :src="address" controls="controls" width="100%" height="100%"></video>
            </div>
            <!-- <span slot="footer" class="dialog-footer">
              <el-button @click="closeVideo">Cancel</el-button>
              <el-button type="primary" @click="closeVideo">Confirm</el-button>
            </span> -->
          </el-dialog>

          <el-collapse v-model="activeNames" v-show="appendix">
            <el-collapse-item title="Reference Materials" name="appendix">
              <div class="attach_arr">
                <div class="appendix" >
                  <div style="text-align: center;margin-right:10px;width:30%" v-for="item in attach_arr" :key="item._id.$id">
                    <div style="text-align:center;margin-top:30px" v-if="item.attach_format !== 'video'">
                      <img class="attach-img" :src="item.attach_avatar_url? 'http://'+item.attach_avatar_url : 'https://i1.wp.com/www.questionpro.com/blog/wp-content/uploads/2015/03/primary-test-it.png?w=293&ssl=1'" alt="">
                    </div>
                    <a class="attach" v-if="item.attach_format !== 'video'" target="_blank" :href="item.attach_address" :download="item.attach_name">{{ item.attach_name }}</a>
                    
                    <div style="text-align:center;margin-top:30px" v-if="item.attach_format === 'video'" @click="playVideo(item.attach_address)">
                      <img class="attach-img" :src="item.attach_avatar_url? 'http://'+item.attach_avatar_url : 'https://i1.wp.com/www.questionpro.com/blog/wp-content/uploads/2015/03/primary-test-it.png?w=293&ssl=1'" alt="">
                      <a>{{ item.attach_name }}</a>
                    </div>
                    <!-- <div v-if="item.attach_format === 'video'" @click="playVideo(item.attach_address)"> -->
                     <!-- <img class="attach-img" :src="item.attach_avatar_ur || 'https://i1.wp.com/www.questionpro.com/blog/wp-content/uploads/2015/03/primary-test-it.png?w=293&ssl=1'" alt=""> -->
                      <!-- <a>{{ item.attach_name }}</a>
                    </div> -->
                  </div>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
          <el-dialog
            title="Video Play"
            :visible.sync="videoDialogVisible"
            width="60%"
            center
            @close="closeVideo">
            <div class="video_container">
              <video class="video" :src="videoAddress" controls="true" controlslist="nodownload" width="80%" height="80%"></video>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="closeDialog">Cancel</el-button>
              <el-button type="primary" @click="closeDialog">Confirm</el-button>
            </span>
          </el-dialog>
          <div class="progress" v-if="proVisible">
            <el-progress :percentage='process'></el-progress>
          </div>
          
          <div style="margin-top:20px">
            <el-select v-model="update_block_content[0]" placeholder="Please select" v-if="content.block_format=='select'">
              <el-option
                v-for="item in selectOptions "
                :key="item"
                :label="item"
                :value="item">
              </el-option>
            </el-select>

          </div>
          <div>
            <div style="margin-top:20px"  v-if="content.block_format==='input'">
              <div v-for="(item, index) in update_block_content" :key="index" >
                  <el-input style="margin-top:10px;" type="text" v-model="update_block_content[index]" ></el-input>
                  <div>
                    <el-select @change="updateCheck" v-if="content.block_review==='yes' && update_check_content && lookType!=='student'" v-model="update_check_content[index]">
                        <el-option value="Pending"></el-option>
                        <el-option value="Verified"></el-option>
                    </el-select>
                    <el-tag style="margin-left:20px" dark :type="update_check_content[index]==='已验证'?'success':'primary'" v-if="content.block_review==='yes' && update_check_content && lookType==='student'">{{update_check_content[index]}}</el-tag>
                  </div>
              </div>
              <span class="submitBtn" v-if="lookType == 'student' || lookType == 'admin' " :loading="s_a" @click="submitBlock">Submit</span>
            </div>
            <div style="margin-top:20px"  v-if="content.block_format==='textarea' ||content.block_format==='pdf'|| content.block_format==='select'">
                <span style="margin-bottom:10px;"  v-if="lookType != 'student' ">Student Answers: </span>

                <div v-for="(item, index) in update_block_content"  :key="index" >
                    <!-- <el-input style="margin-top:10px;" type="textarea" v-model="update_block_content[index]"></el-input> -->

                    <quill-editor class="editor2" ref="myTextEditor" v-model="update_block_content[index]"
                        :options="editorOption">
                    </quill-editor>

                    <div style="margin-top:20px">
                        <span class="removeInput" @click="removeInput(index)" v-if="lookType == 'student'">Remove the Textbox</span>
                        <el-select style="margin-left:10px" @change="updateCheck" v-if="content.block_review==='yes' && update_check_content && lookType!=='student'" v-model="update_check_content[index]">
                            <el-option value="Pending"></el-option>
                            <el-option value="Verified"></el-option>
                        </el-select>
                        <el-tag style="margin-left:20px" dark :type="update_check_content[index]==='已验证'?'success':'primary'" v-if="content.block_review==='yes' && update_check_content && lookType==='student'">{{update_check_content[index]}}</el-tag>
                    </div>
                </div>
              <span class="addInput1" v-if="lookType == 'student'&&content.multi_allowed === 'yes'" @click="addTextarea">Add a Textbox</span>


              
              <div style="display: none;"></div>
              <!-- <input class="file" name="file" type="file"  accept="*" @change="getFile" v-if="lookType == 'student'" /><br/> -->
              <div style="position: relative;" v-if="content.block_format!='select'">

                <input class="myFileUpload"  name="file" type="file"  accept=".pdf" @change="getFile" />

                <span class="addInput2">
                  Add an Attachment
                </span>
                
                <!-- <span class="submitBtn1" style="padding:8px 20px" @click="centerDialogVisible = true" :loading="upLoading">
                <i class="el-icon-plus"></i>
                Add an Attachment</span> -->
              </div>

              <el-collapse style="margin-top: 10px;" v-model="activeNames" v-show="content_attach.length !== 0">
                <el-collapse-item title="Attachments" name="1" v-show="content_attach.length !== 0">

                  <ul v-for="(item,index) in content_attach" :key="item._id.$id" class="attachItem">
                    <div>
                      <span class="attachType">{{item.attach_type}}:</span>
                      <el-popover
                        placement="top-start"
                        width="200"
                        trigger="hover"
                        :content="item.attach_key">
                        <a class="attach" target="_blank" :href="item.attach_address" :download="item.attach_name" v-if="
                        !item.edit" slot="reference">{{item.attach_name }}  </a>
                      </el-popover>
                      <input type="text" v-model="item.attach_name " v-if="item.edit" />
                    </div>
                    <div>
                      <span class="deleteAttach" @click="deleteAttach(index)">Delete</span>
                      <span class="deleteAttach" @click="editAttach(index)" v-if="!item.edit">Edit</span>
                      <span class="deleteAttach" @click="confirmAttach(index)" v-if="item.edit">Confirm</span>
                    </div>
                  </ul>

                  <!-- <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div> -->
                </el-collapse-item>

              </el-collapse>

              <el-pagination
                background
                layout="prev, pager, next"
                :total="past_content_array.length+1"
                :page-size="1"
                style="margin-top:10px"
                v-if="lookType != 'student'"
                @current-change="changePage"
                >
              </el-pagination>
              <el-dialog
                title="Upload Settings"
                :visible.sync="centerDialogVisible"
                width="720px"
                center>
                <div style="padding:0 50px 0 50px">
                <div class="select">
                  <div>File Type:</div>
                  <el-select v-model="selectType" placeholder="File Type" style="width:100%;margin-top:10px" size="medium">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </div>
                <div>
                  <div>Attachment Name：</div>
                  <el-input v-model="videoName" placeholder="Please input the name of your attachment" size="medium" style="margin-top:10px"></el-input>
                  <p v-if="selectType == 'file' || selectType == 'gongzhonghao' || selectType == 'website' " style="margin-top:10px">URL Link to Your Attachment：</p>
                  <el-input v-model="videoPath" placeholder="Please provide a url link to your attachment" v-if=" selectType == 'file' || selectType == 'gongzhonghao' || selectType == 'website'" style="margin-top:10px"></el-input>
                </div>

                <div v-if=" selectType == 'pdf' || selectType == 'image'">

                  <input class="hidden" name="file" type="file"  accept="*" @change="getFile" v-if=" selectType == 'pdf' || selectType == 'image'" /><br/>

                  <el-button type="primary" size="medium">
                    Upload Files
                  </el-button>

                  <span>{{ file?file.name : '' }}</span>
                </div>

                <div v-if=" selectType == 'video'">
                  <input class="hidden" name="file" type="file"  accept="*" @change="getVideoFile" v-if=" selectType == 'video'" /><br/>

                  <el-button type="primary" size="medium">
                    Upload Files
                  </el-button>

                  <span>{{ file?file.name : '' }}</span>
                </div>
                <!-- <input class="file" name="file" type="file"  accept="*" @change="getFile" v-if=" selectType == 'pdf' || selectType == 'image'" /><br/> -->
                <!-- <input class="file" name="file" type="file"  accept="*" @change="getVideoFile" v-if=" selectType == 'video'" /><br/> -->

                <div class="intro">
                  Attachment notes:
                  <el-input
                    type="textarea"
                    :rows="5"
                    placeholder="Please input notes for your attachment"
                    style="margin-top:10px;width: 100%;"
                    v-model="intro">
                  </el-input>
                  </div>
                  <span slot="footer" class="dialog-footer">
                    <span v-if=" selectType == 'video'" style="font-size:12px;color:#ccc;margin-right:20px">The file size of your attachment cannot exceed 1 GB</span>
                    <span v-if=" selectType == 'pdf' || selectType == 'image'" style="font-size:12px;color:#ccc;margin-right:20px">The file size of your attachment cannot exceed 5 MB</span>
                    <div class="diaFoot">
                      <span @click="centerDialogVisible = false" class="removeInput" style="padding:6px 20px">Cancel</span>
                      <span type="primary" @click="uploadBlock" class="addInput" style="padding:6px 20px;margin-left: 10px;">Confirm</span>
                    </div>

                  </span>
                </div>
              </el-dialog>

              <!-- <div class="select">
                上传类型:
                <el-select v-model="selectType" placeholder="选择上传类型">
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <el-input v-model="videoName" placeholder="请输入视频名称" v-if="lookType == 'student' && selectType == 'video'"></el-input>
              <el-input v-model="videoPath" placeholder="请输入视频地址" v-if="lookType == 'student' && selectType == 'video'"></el-input>
              <input class="file" name="file" type="file"  accept="*" @change="getFile" v-if="lookType == 'student' && (selectType == 'pdf' || selectType == 'image')" /><br/>
              <div class="intro">
                附件说明:
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="请输入附件说明"
                  style="margin-top:10px;"
                  v-model="intro">
                </el-input>
              </div> -->
              <span class="submitBtn" v-if="lookType == 'student' || lookType == 'admin' " :loading="s_a" @click="submitBlock">Submit</span>

              <span v-if="latest_save_time !='No Records'" style="margin-left:10px;color: #999;font-size: 14px;"  >Last Updated By {{ latest_save_name   + ' on '+  latest_save_time}}</span>
            </div>

          </div>
          <div v-if="content.block_name">
            <div class="addCommets" v-if="lookType !== 'student' ">
                <el-input style="margin-top:10px;"   type="textarea" v-model="educator_comment" ></el-input>
                <el-button style="margin-top:20px" :disabled="!isAnswer && content.block_format !== 'blank'" @click="submitComment">Add a Comment</el-button>

            </div>
            <div class="comments" v-if="comments.length !== 0">
                <!-- <div style="margin-bottom:20px">我的内容：{{my_block}}</div> -->
                Mentor Comments: {{comments.length === 0 ? 'None': ''}}
                <div class="editComments" v-for="(item) in comments" :key="item._id.$id" style="margin-top: 10px;">
                  <p v-if="!item.edit">{{'Mentor Comment on ' + item.timeStr}}</p>
                  <p style="margin-top: 10px;" v-if="!item.edit">{{item.comment_text}}</p>
                  <el-input  v-if="item.edit" style="margin-top:10px;" type="textarea" v-model="item['comment_text']" ></el-input>

                  <el-button size="mini"

                  @click="editComment(item)"
                  v-if="item.educator_id == studentid && !item.edit"
                  >Edit</el-button>
                  <el-button size="mini"

                  @click="editCommentComfirm(item)"
                  v-if="item.educator_id == studentid && item.edit"
                  >Confirm</el-button>
                  <el-button
                  size="mini"

                  :disabled="!isAnswer"
                  v-if="item.educator_id == studentid || lookType=='admin' "
                  @click="delComment(item._id.$id)">Delete</el-button>

                </div>
            </div>
            </div>

        </el-main>
        <!-- <div class="console">
            console
        </div> -->
      </el-container>

    </el-container>


    <el-dialog :visible.sync="showMentorCritiqueDialog" width="700px">

      <div slot="title" style="font-size: 25px;font-weight: 500;color: #0E4890;margin-top: 0px;">
          Mentor Critique
        </div>

      <div style="width: 100%;height: 260px;max-height: 260px; margin-top: -30px;">
        


        <div class="mentorInfoClass">
          Each team is entitled to ONE topic review and TWO report critiques. Mentors will offer feedback on your current content to assist your team in revising the project or report. 
          <span style="color: red;word-wrap: break-word;">Use the buttons below judiciously, as each can be clicked only once.</span> 
        </div>
        <div style="clear: both;"></div>

        <div style="width: 100%;height: 180px;margin-top: 20px;">

          <div class="eventLinkStyle" >


            <div @click="addDraft('topic_review')" :style="DraftStatus.topic_review == false || critiqueStatus.topic_review_status == true ? 'background-color: #f5f5f5;color: #77797b;' : 'background-color: #204386;color: white;  cursor:pointer;'" class="eventName">
              Topic Review
            </div>

            <div class="linkStyle" :style="DraftStatus.topic_review == true ? 'background-color: #f5f5f5;' : 'background-color: #e0ebf6;'">
              Request this when you have completed the Project Title and
              Topic/Problem Description sections.
            </div>
            
          </div>

          <div class="eventLinkStyle" >
            <div @click="addDraft('first_critique')" :style="DraftStatus.first_critique == false || critiqueStatus.second_critique_status == true ? 'background-color: #f5f5f5;color: #77797b;' : 'background-color: #204386;color: white; cursor:pointer;'" class="eventName">
              First Critique
            </div>
            <div class="linkStyle1" :style="DraftStatus.first_critique == true ? 'background-color: #f5f5f5;' : 'background-color: #e0ebf6;'">
              Request this after you have completed at least 50% of the entire content.
            </div>
          
          </div>



          <div class="eventLinkStyle">
              <div @click="addDraft('second_critique')" :style="DraftStatus.second_critique == false || critiqueStatus.second_critique_status == true ? 'background-color: #f5f5f5;color: #77797b;' : 'background-color: #204386;color: white; cursor:pointer;'" class="eventName">
                Second Critique
              </div>
            
              <div class="linkStyle" :style="DraftStatus.second_critique == true ? 'background-color: #f5f5f5;' : 'background-color: #e0ebf6;'">
                Request this after you have completed all content except for
                the Review & Reflections and Team Credits sections.
              </div>
            
          </div>



        </div>


      </div>

      
      

    </el-dialog>
  </div>
</template>

<script>
import {
  getContent, getTemplate,
  updateStudentBlock,
  netService,
  upload,
  sendEmail,
  updateStudentBlockCheck,
  getDraftStatus,
  addDraftStatus,
  uploadBlockAttachNew
} from '../../api/pgservice.js'
import {  getQNToken } from '../../service.js'
import { Loading } from 'element-ui';
import Cookie from 'js-cookie'
// import { removeUserId, getUserId, getUserAccountID, getUserType } from '../../utils/store.js'
import Quiz from './Quiz'
import * as qiniu from 'qiniu-js'

import '../../assets/common/font.css'

export default {
  name: 'Home',
  props: {
    msg: String
  },
  components: {
    Quiz
  },
  mounted () {
    // const user_type = getUserType()

    let user_type = 'student'

    if (Cookie.get("loginType") == '学生'){
       user_type = 'student'

       this.studentid = Cookie.get("loginId")
       this.studentAccountId = Cookie.get("loginAccountId")

    }
    if (Cookie.get("loginType") == '教师'){
       user_type = 'adviser'

       this.studentid = this.$router.currentRoute.query.student_id
       this.studentAccountId = this.$router.currentRoute.query.accountid

    }
    if (Cookie.get("loginType") == 'admin'){
        user_type = 'admin'

       this.studentid = Cookie.get("loginId")

    }


    // todo  学生id  lookType 默认学生
    // this.studentid = getUserId()

   
    this.lookType = user_type
    // this.studentAccountId = getUserAccountID()

    

    this.template_id = this.$router.currentRoute.query.template_id
    this.project_id = this.$router.currentRoute.query.project_id
    this.application_team_code = this.$router.currentRoute.query.application_team_code
    this.accountid = this.$router.currentRoute.query.accountid
    this.now_block_account_id = this.$router.currentRoute.query.accountid
    // getProjectTeamcodes({
    //   project_id: this.project_id
    // }).then(data => {
    //   console.log(data)
    // })
    let loadingInstance = Loading.service({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
    });
    // this.studentId = Cookie.get('studentid')
    getTemplate({ template_id: this.template_id }).then(data => {
      this.contents = data.block_contents
      this.menuItems = this.handleList(data.block_contents)
      this.template_name = data.template_info.template_name
      loadingInstance.close()
      // this.template_description = this.template_info.template_description
    })

    // getContents({session_id: this.session_id,student_id:this.studentId}).then((data) => {
    //   // console.log(data)
    //   this.menuItems = this.handleList(data)
    //   // console.log(this.menuItems)
    // })
  },
  methods: {
    logout () {
      // removeUserId()
      console.log('out')
      Cookie.remove('loginId')
      this.$router.push('/login')
    },
    closeVideo () {
      this.dialogVisible = false
      this.address = ''
    },
    playVideo (address) {

    console.log(12222,address)
      this.dialogVisible = true
      this.address = address
    },
    openDialog (e) {
      this.videoDialogVisible = true
      this.videoAddress = e
    },
    submitBlock () {
      this.s_a = true
      console.log(this.content, this.application_team_code)
      updateStudentBlock({
        team_code: this.application_team_code,
        project_id: this.project_id,
        account_id: this.lookType === 'student' ? this.now_block_account_id : this.accountid,
        block_id: this.content.id,
        block_content: this.update_block_content,
        update_user:this.studentid
      }).then(data => {
        if (data == null) {
          this.$message({
            message: 'Submit Successful',
            type: 'success'
          })
          this.s_a = false

          this.getSB()
        }
      })
    },
    getChecksByBlock (block_id) {
      netService({
        functionName: 'getChecksByBlock',
        block_id,
        team_code: this.application_team_code,
        student_id: this.studentAccountId,
        account_id: (this.lookType === 'student' ? this.now_block_account_id : this.accountid)
      }).then(data => {
        console.log(data, '@!!!!!!')
        this.checklist = data
      })
    },
    handleCheckedCitiesChange (value) {
      this.switchCheckBlockByStudent(value._id.$id)
    },
    switchCheckBlockByStudent (check_id) {
      netService({
        functionName: 'switchCheckBlockByStudent',
        team_code: this.application_team_code,
        student_id: this.studentAccountId,
        account_id: (this.lookType === 'student' ? this.now_block_account_id : this.accountid),
        project_id: this.project_id,
        check_id
      }).then(data => {
        console.log(data, '@!!!!!!')
        // this.checklist = data
      })
    },
    uploadBlock () {
      this.upLoading = true
      if (this.selectType === 'pdf' || this.selectType === 'image') {
        this.file && this.uploadFile()
        this.centerDialogVisible = false
      }

      if (this.selectType === 'video') {
        this.file && this.uploadVideoFile()
        this.centerDialogVisible = false
      }
      if (this.selectType === 'file' || this.selectType === 'gongzhonghao' || this.selectType === 'website') {
        netService({
          functionName: 'uploadStaticBlockAttach',
          block_id: this.content.id,
          project_id: this.project_id,
          account_id: (this.lookType === 'student' ? this.now_block_account_id : this.accountid),
          team_code: this.application_team_code,
          attach_name: this.videoName,
          attach_type: this.selectType,
          attach_address: this.videoPath,
          attach_intro: this.intro
        }).then(data => {
          console.log(data)
          this.centerDialogVisible = false
          this.upLoading = false
          this.$message({
            message: 'Submit Successful',
            type: 'success'
          })
          this.reload()
        })
      }
    },
    submitComment () {
      netService({
        functionName: 'updateBlockComment',
        project_id: this.project_id,
        team_code: this.application_team_code,
        account_id: this.accountid,
        educator_id: this.studentid,
        student_block_id: this.student_block_id,
        comment_text: this.educator_comment
      }).then(data => {
        console.log(data)
        this.getSB()
      })
    },
    renderContent (h, { node, data }) {
      // console.log(2222,data)
      // <i class="el-icon-success"></i>
      // complete_status ? 没找到这个值
      if (this.content && this.content.id === data.block_info.id) {
        return (
          <span class="el-tree-node__label">
            <img src={require('../../assets/newLogo.png')}></img>
            <span title={node.label}>{node.label}{node.complete_status}</span>
          </span>)
      } else {
        return (
          <span class="el-tree-node__label">
            <span title={node.label}>{node.label}{node.complete_status}</span>
          </span>)
      }
    },
    handleNodeClick (data) {
      this.contents.map((item) => {
        if (item.block_info.block_name === data.block_info.block_name) {
          if (item.attach_arr.length === 0) {
            this.appendix = false
            this.attach_arr = []
          } else {
            this.appendix = true
            this.attach_arr = item.attach_arr
          }
        }
      })
      if (data.block_info.block_type === 'VIDEO') {
        getContent({ content_id: data.content_info.id }).then((data) => {
          // console.log(data.content_quiz)
          this.quizsByTimes = {}
          this.isBeginQuiz = false
          this.quizs = []
          this.videoDone = false
          const quizsByTimes = this.quizsByTimes
          this.content_quiz = data.content_quiz
          data.content_quiz.map((v) => {
            if (quizsByTimes[+v.quiz_position]) {
              quizsByTimes[v.quiz_position].push(v)
            } else {
              quizsByTimes[v.quiz_position] = [v]
            }
          })
          // this.quizs = [...data.content_quiz]
          // quizsByTimes
        })
      }
      if (data.block_info.block_format === 'select') {
        console.log(data, 'xxxxxxx')

        this.selectOptions = data.block_info.block_options_array
      }
      // content important!!!!!!!
      this.content = data.block_info

      this.getBlockAttaches()
      // this.block_id = data._id.$id
      this.getSB()
      this.getChecksByBlock(this.content.id)
      // getContent({content_id: data.content_info.content_id}).then((i) => {
      // })
    },

    getBlockAttaches () {
      netService({
        functionName: 'getBlockAttaches',
        block_id: this.content.id,
        project_id: this.project_id,
        account_id: (this.lookType === 'student' ? this.now_block_account_id : this.accountid),
        team_code: this.application_team_code
      }).then(data => {
        console.log(data)
        this.s_a = false
        this.content_attach = data
        this.content_attach.map((item) => {
          if (item.attach_address.substr(0, 7).toLowerCase() === 'http://' || item.attach_address.substr(0, 8).toLowerCase() === 'https://') {
            // item.attach_address = item.attach_address
          } else {
            item.attach_address = 'http://' + item.attach_address
          }
        })
      })
    },
    getSB () {
      const id = (this.lookType === 'student' ? 'student_id' : 'account_id')
      console.log(id, this.accountid)
      netService({
        functionName: 'getStudentBlock',
        team_code: this.application_team_code,
        project_id: this.project_id,
        [id]: (this.lookType === 'student' ? this.studentid : this.accountid),
        block_id: this.content.id,
        account_id: this.accountid

      }).then(data => {
        console.log(data)
        // this.now_block_account_id = data.account_id
        this.update_block_content = data.block_content.length === 0 ? [''] : data.block_content
        if (data.check_content) {
          this.update_check_content = data.check_content
        } else {
          this.update_check_content = new Array(data.block_content.length === 0 ? 1 : data.block_content.length).fill('Pending')
        }
        this.block_content = data.block_content
        if (data.check_content) {
          this.check_content = data.check_content
        }

        let options = { year: 'numeric', month: 'long', day: 'numeric' };


        this.comments = data.block_comments.map(v => {

          const commentDate = new Date(v.create_time * 1000)

          v.timeStr = commentDate.toLocaleDateString("en-US", options)
          v.edit = false
          return v
        })
        const date = new Date(data.latest_save_time * 1000)


        this.latest_save_time = data.latest_save_time ? date.toLocaleDateString("en-US", options) + ' at ' + date.toLocaleTimeString() : 'No Records'
        this.now_save_time = data.latest_save_time

        this.latest_save_name = data.latest_save_name ? data.latest_save_name : ''
        this.isAnswer = !!data._id
        this.student_block_id = data._id.$id

        if (this.lookType !== 'student') {
          if (data.past_content_array) {
            this.past_content_array = data.past_content_array
          }
        }
      })
    },
    handleList (list) {
      const result = []
      list.map(v => {
        this.dfs(result, v)
      })
      return result
    },
    dfs (result, v) {
      // console.log(v, result)
      if (+v.block_info.block_level === 1) {
        result.push({
          label: v.block_info.block_name,
          children: [],
          complete_status: v.complete_status,
          block_info: {
            ...v.block_info,
            id: v._id.$id
          }
        })
      } else {
        const len = result.length
        const l = +v.block_info.block_level
        v.block_info.block_level = l - 1
        v.block_info.block_raw_level = l
        this.dfs(result[len - 1].children, v)
      }
    },
    onQuizDone () {
      this.isBeginQuiz = false
      this.quizs = []
      this.videoDone || this.videoElement.play()
      this.videoDone = false
    },
    timeupdate (event) {
      const currentTime = event.target.currentTime// 获取当前播放时间
      this.videoElement = event.target
      // console.log(currentTime,this.quizsByTimes[~~currentTime],this.quizsByTimes);//在调试器中打印
      if (this.quizsByTimes[~~currentTime]) {
        this.quizs = this.quizsByTimes[~~currentTime]
        setTimeout(() => {
          this.videoElement.pause()
        }, 1000)
        this.isBeginQuiz = true
      }
    },
    videoEnd () {
      if (this.quizsByTimes[-1]) {
        this.quizs = this.quizsByTimes[-1]

        this.isBeginQuiz = true
        this.videoDone = true
      }
    },
    addTextarea () {
      this.update_block_content.push('')
      this.update_check_content.push('Pending')
    },
    removeInput (index) {
      console.log(index)
      this.update_block_content.splice(index, 1)
      this.update_check_content.splice(index, 1)
    },
    async getQiniuToken () {
      return new Promise((resolve) => {
        getQNToken().then((res) => {
          resolve(res)
        })
      })
    },
    async uploadVideoFile () {
      console.log(1232131213)

      const result = await this.getQiniuToken()

      console.log(8888, result)

      if (result.data.code === 0) {
        console.log(1222)

        this.proVisible = true
        this.process = 1

        const token = result.data.data
        this.observable = qiniu.upload(
          this.file,
          new Date().getTime() + this.file.name,
          token
        )
        const observer = {
          complete: (res) => {
            var url = 'https://ap.learningfirst.tech/' + res.key

            this.proVisible = false

            this.upLoading = false

            this.uploadVideoC(url)
          },
          next: (res) => {
            this.process = parseFloat(res.total.percent.toFixed(1))
          }
        }
        this.observable.subscribe(observer)
      } else {
      }
    },

    uploadVideoC (url) {
      netService({
        functionName: 'uploadStaticBlockAttach',
        block_id: this.content.id,
        project_id: this.project_id,
        account_id: (this.lookType === 'student' ? this.now_block_account_id : this.accountid),
        team_code: this.application_team_code,
        attach_name: this.videoName,
        attach_type: this.selectType,
        attach_address: url,
        attach_intro: this.intro
      }).then(data => {
        console.log(data)
        this.centerDialogVisible = false
        this.upLoading = false
        this.$message({
          message: 'Submit Successful',
          type: 'success'
        })
        this.getBlockAttaches()
      })
    },

    getVideoFile (e) {
      const file = e.target.files[0]

      console.log(file)
      if (file.size / 1024 / 1024 > 1024) {
        this.$message({
          message: 'The file size cannot exceed 1024 MB. Please upload again.',
          type: 'error'
        })
        this.file = null
        return false
      } else {
        this.file = file
      }
    },


    async uploadPDFFile () {
      console.log(1232131213)

      const result = await this.getQiniuToken()

      console.log(8888, result)

      if (result.data.code === 0) {
        console.log(1222)

        this.proVisible = true
        this.process = 1

        const token = result.data.data
        this.observable = qiniu.upload(
          this.file,
          new Date().getTime() + this.file.name,
          token
        )
        const observer = {
          complete: (res) => {
            var url = 'https://ap.learningfirst.tech/' + res.key

            this.proVisible = false

            this.upLoading = false

            this.uploadFile(url,res.key)
          },
          next: (res) => {
            this.process = parseFloat(res.total.percent.toFixed(1))
          }
        }
        this.observable.subscribe(observer)
      } else {
      }
    },

    getFile (e) {
      const file = e.target.files[0]

      console.log(file)
      if (file.size / 1024 / 1024 > 20) {
        this.$message({
          message: 'The file size cannot exceed 20 MB. Please upload again.',
          type: 'error'
        })
        this.file = null
        return false
      } else {
        this.file = file
      }

      console.log(123,this.file)

      this.videoName = this.file.name
      this.uploadPDFFile()
    },
    uploadFile (url,key) {


      


      netService({
        functionName: 'uploadBlockAttachNew',
        block_id: this.content.id,
        project_id: this.project_id,
        account_id: (this.lookType === 'student' ? this.now_block_account_id : this.accountid),
        team_code: this.application_team_code,
        attach_name: this.videoName,
        attach_type: this.selectType,
        attach_address: url,
        key: key,
        attach_intro: this.intro
      }).then(data => {
        console.log(data)
        this.centerDialogVisible = false
        this.upLoading = false
        this.$message({
          message: 'Submit Successful',
          type: 'success'
        })
        this.getBlockAttaches()
      })


      // const param = new FormData() // 创建form对象
      // const _this = this
      // this.proVisible = true
      // this.process = '0'
      // param.append('file', this.file)// 通过append向form对象添加数据

      // param.append('function', 'uploadBlockAttach')
      // param.append('project_id', this.project_id)
      // param.append('attach_type', this.selectType)
      // param.append('attach_intro', this.intro)
      // param.append('team_code', this.application_team_code)
      // param.append('account_id', (this.lookType === 'student' ? this.now_block_account_id : this.accountid))
      // param.append('block_id', this.content.id)
      // param.append('attach_name', this.videoName)

      // console.log(param.get('file')) // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      // const config = {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      //   onUploadProgress: function (progressEvent) { // 原生获取上传进度的事件
      //     console.log(progressEvent)
      //     if (progressEvent.lengthComputable) {
      //       // 属性lengthComputable主要表明总共需要完成的工作量和已经完成的工作是否可以被测量
      //       // 如果lengthComputable为false，就获取不到progressEvent.total和progressEvent.loaded
      //       const loaded = progressEvent.loaded
      //       const total = progressEvent.total
      //       _this.$nextTick(() => {
      //         _this.process = parseFloat(((loaded / total) * 100).toFixed(1))
      //       })
      //       console.log(_this.process)
      //     }
      //   }
      // } // 添加请求头
      // upload({ param, config })
      //   .then(response => {
      //     console.log(response.data)
      //     this.$message({
      //       message: 'Submit Successful',
      //       type: 'success'
      //     })
      //     this.upLoading = false
      //     this.proVisible = false
      //     this.getBlockAttaches()
      //   })
    },

    deleteAttach (index) {
      netService({
        functionName: 'removeBlockAttach',

        attach_id: this.content_attach[index]._id.$id

      }).then(data => {
        // console.log(data)
        this.getBlockAttaches()
      })
      return false
    },
    editAttach (index) {
      this.content_attach[index].edit = true
      this.content_attach = [...this.content_attach]
    },
    confirmAttach (index) {
      this.content_attach[index].edit = false

      netService({
        functionName: 'updateAttachName',

        attach_id: this.content_attach[index]._id.$id,
        new_name: this.content_attach[index].attach_name
      }).then(data => {
        // console.log(data)
        this.getBlockAttaches()
      })
    },
    delComment (id) {
      console.log(id)
      netService({
        functionName: 'deleteBlockComment',

        student_block_id: this.student_block_id,
        comment_id: id

      }).then(data => {
        // console.log(data)
        this.getSB()
      })
    },

    editComment (item) {
      item.edit = true
      this.comments = {
        ...this.comments
      }
      console.log(this.comments)
    },

    editCommentComfirm (item) {
      netService({
        functionName: 'editBlockComment',

        comment_id: item._id.$id,
        comment_text: item.comment_text

      }).then(data => {
        // console.log(data)
        this.getSB()
      })
    },
    getPdf () {
      if(!this.content || !this.content.id || this.content.id == ''){
          this.$message.error('Please select a section on the left.')
          return 
        }
      console.log('121343')
      console.log(this.project_id)
      console.log(this.lookType === 'student' ? this.studentAccountId : this.accountid)
      console.log(this.application_team_code)
      this.$router.push({
        path: 'toPrint',
        query: {
          project_id: this.project_id,
          // account_id :(this.lookType == 'student' ?this.studentAccountId:this.accountid),
          account_id: this.accountid,
          team_code: this.application_team_code,
          project_product:this.project_product,
          block_id: this.content.id,
          template_id:this.template_id
        }
      })
    },

    closeDialog () {
      this.videoDialogVisible = false
      this.videoAddress = ''
    },

    changePage (e) {
      console.log(e)
      if (e !== 1) {
        const index = e - 2
        this.update_block_content = this.past_content_array[index].block_content
        if (this.past_content_array[index].check_content) {
          this.update_check_content = this.past_content_array[index].check_content
        } else {
          this.update_check_content = new Array(this.update_block_content.length).fill('Pending')
        }
        const date = new Date(this.past_content_array[index].save_time * 1000)
        let options = { year: 'numeric', month: 'long', day: 'numeric' };

        this.latest_save_time = this.past_content_array[index].save_time ? date.toLocaleDateString("en-US", options) + ' at ' + date.toLocaleTimeString() : 'No Records'
      } else {
        this.update_block_content = this.block_content
        if (this.check_content) {
          this.update_check_content = this.check_content
        } else {
          this.update_check_content = new Array(this.update_block_content.length).fill('Pending')
        }
        const date = new Date(this.now_save_time * 1000)
        let options = { year: 'numeric', month: 'long', day: 'numeric' };

        this.latest_save_time = this.now_save_time ? date.toLocaleDateString("en-US", options) + ' at ' + date.toLocaleTimeString() : 'No Records'
      }
    },
    sendEmail () {
      sendEmail(this.studentid, this.project_id).then(res => {
        this.$message({
          message: 'Submit Successful',
          type: 'success'
        })
      })
    },
    updateCheck () {
      updateStudentBlockCheck(this.content.id, this.project_id, this.application_team_code, this.accountid, this.update_check_content).then(res => {
        this.$message.success('Update Success')
      })
    },

    mentorCritique(){

  //     application_team_code,
  // project_id,
  // account_id,
  // template_id


      getDraftStatus({
        application_team_code: this.application_team_code,
        project_id:this.project_id,
        account_id:this.accountid,
        template_id:this.template_id,

      })
      .then(res => {


        this.DraftStatus = res.data.data
        this.critiqueStatus = res.data.draftStatus
        this.showMentorCritiqueDialog = true
        console.log(123000000,res)
        // this.$message.success('Update Success')
      })

     

      // var  infoStr = '';

      // if (index == 0){
      //   infoStr = '每个项目都需要通过选题评审，请确认你们已经按要求填写Project Title。提交申请后，导师将对你们提交的选题进行审核。是否确认提交？'
      // }
      // else if (index == 1 || index == 2){
      //   infoStr = '每个项目有初稿和二稿共两次点评机会，提交申请后，导师将对你们提交的内容进行点评。是否确认提交？'
      // }else{
      //   infoStr = '提交终稿后，你们将不能再对项目进行编辑。你们的项目将进入评审阶段。是否确认提交终稿？'
      // }


      // this.$confirm(infoStr, '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(() => {
      //   this.addDraft()
      // }).catch(() => {
              
      // });

    },
    addDraft(item){


      if (item == 'topic_review'){
        if (this.DraftStatus.topic_review == false || this.critiqueStatus.topic_review_status == true ){
          return
        }
        
      }

      if (item == 'first_critique'){


        if (this.critiqueStatus.topic_review_status == false ){

          this.$message.error('Please request critique in order')

          return
        }

        


        if (this.DraftStatus.first_critique == false || this.critiqueStatus.first_critique_status == true ){
          return
        }
        
      }

      
      if (item == 'second_critique'){

        if (this.critiqueStatus.topic_review_status == false || this.critiqueStatus.first_critique_status == false){

          this.$message.error('Please request critique in order')

          return
        }

        if (this.DraftStatus.second_critique == false || this.critiqueStatus.second_critique_status == true ){
          return
        }
        
      }


      addDraftStatus({
        application_team_code: this.application_team_code,
        project_id:this.project_id,
        account_id:this.accountid,
        template_id:this.template_id,
        event:this.projectTeamEvent,
        type:item
      })
      .then(res => {


        this.mentorCritique()
        this.$message.success('Critique Request Successful')
      })


    },

  },
  data () {
    return {
      activeC: 'default',
      s_a: false,
      isAnswer: false,
      educator_comment: '',
      lookType: 'student',
      latest_save_time: '',
      latest_save_name:'',
      template_description: '',
      template_name: '',
      videoDone: false,
      process: '0',
      proVisible: false,

      update_block_content: [''],
      update_check_content: '',
      my_block: '',
      comments: [],

      videoElement: null,
      session_id: '',
      menuItems: [],
      defaultProps: {
        children: 'children',
        label: 'label',
        content_info: 'content_info'
      },
      checklist: [],
      content: {},
      studentId: '',
      quizs: [],
      isBeginQuiz: false,
      quizsByTimes: {},
      content_quiz: [],

      content_attach: [],
      activeNames: ['1', 'appendix'],
      file: null,
      studentid: '',
      nowAttachName: '',
      past_content_array: [],
      block_content: [],
      check_content: '',
      options: [{
        value: 'video',
        label: 'video'
      }, {
        value: 'pdf',
        label: 'pdf'
      }, {
        value: 'image',
        label: 'image'
      }, {
        value: 'file',
        label: 'Cloud Storage'
      }, {
        value: 'gongzhonghao',
        label: 'Blog'
      }, {
        value: 'website',
        label: 'Website'
      }],
      selectType: 'pdf',
      videoPath: '',
      videoName: '',
      intro: '',
      centerDialogVisible: false,
      upLoading: false,

      selectOptions: [],
      selectValue: '',
      attach_arr: [],
      appendix: false,
      dialogVisible: false,
      videoDialogVisible: false,
      videoAddress: '',
      address: '',
      now_block_account_id: '',
      accountid: '',
      projectTeamRole: Cookie.get("projectTeamRole"),
      projectTeamEvent:Cookie.get("projectTeamEvent"),
      showMentorCritiqueDialog:false,
      DraftStatus:{},
      critiqueStatus:{},
      editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        // [{'direction': 'rtl'}],                         // 文本方向
                        [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        [{ font: [] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['link', 'image', 'video'] // 链接、图片、视频
                    ] // 工具菜单栏配置
                },
                placeholder: '', // 提示
                readyOnly: false, // 是否只读
                theme: 'snow', // 主题 snow/bubble
                syntax: true // 语法检测,
            },

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.Home{
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #F5F9FF;
  .head{
    height: 50px;
    background: #204386;
    // background: url('../../assets/pg/header.png');
    background-size: 100% 100%;
    background-position: 0 0;
    line-height: 50px;
    text-align: right;
    .el-icon-setting{
      color: #fff;
      font-size: 18px;
    }
    .btn{
      border: 1.5px solid #fff;
      padding: 1px 12px;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      margin-right: 18px;
      cursor: pointer;
    }
    .btnAttachment{
      border: 1.5px solid #fff;
      padding: 1px 12px;
      border-radius: 5px;
      color: #fff;
      font-size: 14px;
      text-align: center;
      margin-right: 18px;
      clear: both;
      // flex-direction: row;
      // margin-top: 30px;
      cursor: pointer;
    }
  }
  .center{
    flex:1;
    overflow: hidden;
    .aside{
      height: 100%;
      background: #FFFFFF;
      box-shadow: 0px 0px 8px 1px #CEC9C7;
      padding: 0;
      margin: 0;
      ::v-deep .el-collapse-item__header{
        padding: 0px 20px!important;
        height: 60px;
        color: #204386;
        font-weight: bold;
        font-size: 18px;
        border: none;
      }
      ::v-deep .el-collapse{
        border: none;
      }
      ::v-deep .el-collapse-item__wrap{
        border:none
      }
    }
    .centerMain{
      ::v-deep .el-collapse-item__header{
        font-family: "DDINAlternateRegular";
        color: #0E4890;
        border: none;
        height: 20px;
        // background-color: red;
        .el-collapse-item__arrow{
          margin: 0;
          margin-left: 16px;
          font-weight: bold;
        }
      }
      ::v-deep .el-collapse{
        border: none;
      }
      ::v-deep .el-collapse-item__wrap{
        border:none
      }
    }
    .submitBtn{
      display: inline-block;
      margin-top: 36px;
      padding: 6px 20px;
      background: #204386;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
    }
    .submitBtn1{
      display: inline-block;
      margin-top: 36px;
      padding: 6px 20px;
      background: white;
      color: #0E4890;
      border: 1.5px solid #0E4890;
      // color: #fff;
      border-radius: 5px;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
.attach-img{
  /*width:100%;*/
  /*margin-bottom:50px;*/
  /*width: 200px;*/
  height: 150px;
  display: block;
  margin:0 auto 10px auto;
  border:1px solid #ddd;
  font-family: "DDINAlternateRegular";
}
.check-box{
  margin-top: 10px;
  border-radius: 5px;
  background: #F5F9FF;
  padding: 20px;
  font-family: "DDINAlternateRegular";
  .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #0E4890!important;
      border-color: #0E4890!important;
  }
  .el-checkbox__input.is-checked+.el-checkbox__label {
    color: #0E4890!important;
}
}
.check-box-item{
  /* word-wrap:break-word;
word-break:break-all;  */
word-break:normal;
white-space: pre-wrap;
margin-bottom: 10px;
font-weight: bold;
font-size: 14px;
  font-family: "DDINAlternateRegular";
}
.video_container {
  height: 600px;
  background-color:clear;
}
.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%)
}
.appendix {
  margin: 20px;
  font-size: 12px;
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  font-family: "DDINAlternateRegular";
  /* display: grid;
  grid-template-columns: 300px 300px 300px;
  grid-template-rows: 300px 300px 300px; */
  a{
    color: #204386;
    font-size: 14px;
  }
}
.attach_arr {
  margin: 20px 0;
}
.attach_arr p {
  font-size: 13px;
}
.select {
  margin: 20px 0;
}
.intro {
  margin-top: 20px;

}
.pdf{
  position: absolute;
  right: 0;
  top: 5px;
}
.file{
  // display:none;
  margin-top: 20px;
}
.templateHead{
  position: relative;
  padding: 0 10px;
  height: 50px;
}
.comments{
  margin-top: 30px;
  color: #888;
}
  .el-header {
    background-color: #B3C0D1;
    color: #333;
    line-height: 60px;
    font-family: "DDINAlternateRegular";
  }

  .el-aside {
    color: #333;
  }
  .console{
    position: absolute;
    bottom: 0;
    height: 300px;
    width: calc(100% - 201px);
    border-top: 1px solid #ccc;
    font-family: "DDINAlternateRegular";
  }
  .toptitle{
    font-family: DIN Alternate-Bold, DIN Alternate;
    font-weight: bold;
    color: #0E4890;
    font-size: 20px;
    margin-top: 30px;
    // height: 30px;
    // background-color: rebeccapurple;
      .tip{
        margin-left: 10px;
        font-size: 60%;
        background: #c9cae4;
        color: #204386;
        border-radius: 20px;
        // height: 30px;
        padding: 4px 8px;
        font-family: "DDINAlternateRegular";
      }

  }
  #videoPlay{
    width: 100%;
  }
  .title-description{
    font-size: 14px;
    display: contents;
    font-family: "DDINAlternateRegular";
  }
  .content_instruction{
    display: block;
    font-family: DIN Alternate-Bold, DIN Alternate;
    font-weight: bold;
    color: #474747;
    margin-top: 34px;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .deleteAttach{
    cursor: pointer;
    margin-left: 10px;
    padding: 2px 10px;
    border-radius: 5px;
    color: #0E4890;
    border: 1.5px solid #0E4890;
  }
.progress{
  width: 300px;
}
.attachItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F9FF;
  border-radius: 5px;
  padding: 20px;
  margin-top: 10px;
  .attachType {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    font-family: "DDINAlternateRegular";
    font-weight: 400;
    color: #666666;
  }
  .attach{
    color: #0E4890;
    font-size: 16px;
  }
}

.removeInput{
  font-family: "DDINAlternateRegular";
  font-weight: 600;
  color: #0E4890;
  border: 1.5px solid #0E4890;
  border-radius: 5px;
  padding: 3px 12px;
  cursor: pointer;
}
.addInput1{
  font-family: "DDINAlternateRegular";
  font-weight: 600;
  color: #0E4890;
  border: 1.5px solid #0E4890;
  background: white;
  border-radius: 5px;
  padding: 4px 12px;
  cursor: pointer;
  margin-top: 30px;
  display: inline-block;
}

.addInput2{
  font-family: "DDINAlternateRegular";
  font-weight: 600;
  color: #0E4890;
  border: 1.5px solid #0E4890;
  background: white;
  border-radius: 5px;
  padding: 4px 12px;
  cursor: pointer;
  margin-top: 30px;
  display: inline-block;
}
.myFileUpload
{
    position: absolute;
    display: block;
    cursor: pointer;
    // width: 100px;
    // height: 40px;
    top: 30px;
    // background-color: rebeccapurple;
    opacity: 0;
    // top: 10px;
}
.addInput{
  font-family: "DDINAlternateRegular";
  font-weight: 600;
  color: #fff;
  border: 1.5px solid #0E4890;
  background: #0E4890;
  border-radius: 5px;
  padding: 4px 12px;
  cursor: pointer;
  margin-top: 20px;
  display: inline-block;
}
.eventLinkStyle{
  // margin-left: 10px;
  // margin-right: 10px;
  height: 50px;
  margin-top: 10px;
  width: 100%;
  float: left;
  // background-color: red;
}
.eventName{
  color: #333;
  float: left;
  width: 20%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  background-color: #204386;
  border-radius: 5px;

}
.linkStyle1{
  float: left;
  height: 40px;
  width: 75%;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  line-height: 40px;
  background-color: #204386;
  color: #77797b;
  border-radius: 5px;
  word-break: normal;

  // white-space: pre-wrap;
  // overflow: hidden; /* 隐藏超出内容的区域 */
  // white-space: nowrap; /* 不允许文本折行 */
  // text-overflow: ellipsis; /* 显示省略号代替超出部分的文本 */
}
.linkStyle{
  float: left;
  height: 40px;
  width: 75%;
  margin-left: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
  line-height: 19px;
  background-color: #204386;
  color: #77797b;
  // display:table;  
  // vertical-align:middle;  
  // display:table-cell; 
  text-align: left;
  // word-wrap:break-word;
  border-radius: 5px;
  word-break: normal;

  // white-space: pre-wrap;
  // overflow: hidden; /* 隐藏超出内容的区域 */
  // white-space: nowrap; /* 不允许文本折行 */
  // text-overflow: ellipsis; /* 显示省略号代替超出部分的文本 */
}
</style>
<style lang="scss">
.addCommets .el-textarea__inner{
  height: 100px;
}

.editComments .el-textarea__inner{
  height: 100px;
}
.el-textarea__inner{
height: 350px;
}
.intro .el-textarea__inner{
    height: 150px;

}
.btn {
  margin: 20px 0;
}
.green{
  color: green
}
.el-tree-node{
  white-space: normal!important;
  background: #fff;
  color: #8D8D8D;
}
.el-tree-node__content{
  padding: 13px 0!important;
}
.el-tree-node__content:hover{
  background: #204386;
  color: #fff;
}
.el-tree-node:focus>.el-tree-node__content {
    background-color: #204386;
    color: #fff;
}
.el-tree-node__content{
    padding: 15px 0;

  }
  .el-tree-node__label{
    /* overflow: hidden;
    text-overflow: ellipsis; */
    display: flex;
    align-items: center;
    img{
      width:24px;
      margin-right: 10px;
    }
  }
  .el-tree-node__expand-icon + .el-tree-node__label{
    font-weight:bolder;
  }
  .el-tree-node__expand-icon.is-leaf + .el-tree-node__label{
    font-weight: normal;
  }
  video::-internal-media-controls-download-button {
    display:none;
  }

  video::-webkit-media-controls-enclosure {
    overflow:hidden;
  }
  video::-webkit-media-controls-panel {
    width: calc(100% + 30px);
  }
.el-checkbox__input{
  vertical-align: top;
}
.diaFoot{
  margin-top: 10px;
  text-align: right;
}

.hidden{
    opacity: 0;
    position: absolute;
    height: 38px;
    width: 120px;
}

.mentorInfoClass {
  // background-color: rebeccapurple;
  // white-space: pre-wrap;
  // text-overflow: ellipsis;
  // word-wrap: break-word;
  word-break: normal;
  line-height: 20px;
  width: 100%;
  margin-top: 20px;
  margin-left: 0px;
  
  
}


.no-header-dialog .el-dialog__header {
  display: none;
  height:0px;
}

.no-title .el-dialog__title {
  display: none;
}

.editor2 {
    .ql-container {
        height: 220px !important;
    }
}

</style>
